import { default as _12storeezCEr1XBPzDRMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/12storeez.vue?macro=true";
import { default as favoritesv4HUvFB1auMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/favorites.vue?macro=true";
import { default as indexnONaWK4M69Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/index.vue?macro=true";
import { default as ordersN6lAg5GMpCMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/orders.vue?macro=true";
import { default as productsXqLpoHJ6hDMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/products.vue?macro=true";
import { default as salescGGeL1LZO1Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/sales.vue?macro=true";
import { default as setupNotificationsifFPyolAS3Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/setupNotifications.vue?macro=true";
import { default as support_45chatHoo8e9BBnAMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/support-chat.vue?macro=true";
import { default as accountrWaTrbJuwnMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account.vue?macro=true";
import { default as _91brand_93Cbph70QR3pMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[[category]]/[brand].vue?macro=true";
import { default as _91category_93A4Pq0F9as0Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[category].vue?macro=true";
import { default as cartMK3AbecXJZMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/cart.vue?macro=true";
import { default as _91_46_46_46catalog_93HiN10xJQY9Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/catalog/[...catalog].vue?macro=true";
import { default as checkoutny9HcvFmgjMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/checkout.vue?macro=true";
import { default as conciergeGDzwAnPMP6Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/concierge.vue?macro=true";
import { default as _91homeBannerId_938o2QAhM6YhMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/homeBanner/[homeBannerId].vue?macro=true";
import { default as indexOHLNSFu8R2Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/index.vue?macro=true";
import { default as aboutWbZjIlgUfyMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/about.vue?macro=true";
import { default as buyeriGaTIplmp3Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/buyer.vue?macro=true";
import { default as guaranteesQ6P4ukLMEHMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/guarantees.vue?macro=true";
import { default as kontakty88gLHAmIVJMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/kontakty.vue?macro=true";
import { default as ofertaVkcdqhhweKMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/oferta.vue?macro=true";
import { default as pressZqu4nQHvRHMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/press.vue?macro=true";
import { default as sellerbOwxuvNpC4Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/seller.vue?macro=true";
import { default as kidsktE2gt4pnZMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/kids.vue?macro=true";
import { default as lifestyleZdQporhbXKMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/lifestyle.vue?macro=true";
import { default as loginUhr5VTSYdrMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/login.vue?macro=true";
import { default as menf4HlARQYP2Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/men.vue?macro=true";
import { default as _91id_93D5T4XMM9xeMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/opinions/[id].vue?macro=true";
import { default as indexMlLUJXreXBMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/index.vue?macro=true";
import { default as thanksr7YFIN2KS2Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/thanks.vue?macro=true";
import { default as feedeVUa5PVxwPMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/osocial/feed.vue?macro=true";
import { default as feeduGJCNsaovoMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/otrends/feed.vue?macro=true";
import { default as _91product_93rYyo0sS413Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/products/[product].vue?macro=true";
import { default as indexzGdA3SQnqRMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/index.vue?macro=true";
import { default as wishlistEyCNW5XZQQMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/wishlist.vue?macro=true";
import { default as _91profileId_93PssI6OgWWAMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId].vue?macro=true";
import { default as indexkusVu0PLmyMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/index.vue?macro=true";
import { default as konciergesDowZSTDHmMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/promo/koncierge.vue?macro=true";
import { default as vtb7QUnP5mmlhMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/promo/vtb.vue?macro=true";
import { default as publicationxlEpOCSZF6Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/publication.vue?macro=true";
import { default as uikit7CqsqpK9JvMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/uikit.vue?macro=true";
import { default as womencV2muBm1TWMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/women.vue?macro=true";
import { default as ya_45authVS2RvS56riMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/ya-auth.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: _12storeezCEr1XBPzDRMeta?.name ?? "12storeez",
    path: _12storeezCEr1XBPzDRMeta?.path ?? "/12storeez",
    meta: _12storeezCEr1XBPzDRMeta || {},
    alias: _12storeezCEr1XBPzDRMeta?.alias || [],
    redirect: _12storeezCEr1XBPzDRMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/12storeez.vue").then(m => m.default || m)
  },
  {
    name: accountrWaTrbJuwnMeta?.name ?? undefined,
    path: accountrWaTrbJuwnMeta?.path ?? "/account",
    meta: accountrWaTrbJuwnMeta || {},
    alias: accountrWaTrbJuwnMeta?.alias || [],
    redirect: accountrWaTrbJuwnMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: favoritesv4HUvFB1auMeta?.name ?? "account-favorites",
    path: favoritesv4HUvFB1auMeta?.path ?? "favorites",
    meta: favoritesv4HUvFB1auMeta || {},
    alias: favoritesv4HUvFB1auMeta?.alias || [],
    redirect: favoritesv4HUvFB1auMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexnONaWK4M69Meta?.name ?? "account",
    path: indexnONaWK4M69Meta?.path ?? "",
    meta: indexnONaWK4M69Meta || {},
    alias: indexnONaWK4M69Meta?.alias || [],
    redirect: indexnONaWK4M69Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: ordersN6lAg5GMpCMeta?.name ?? "account-orders",
    path: ordersN6lAg5GMpCMeta?.path ?? "orders",
    meta: ordersN6lAg5GMpCMeta || {},
    alias: ordersN6lAg5GMpCMeta?.alias || [],
    redirect: ordersN6lAg5GMpCMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: productsXqLpoHJ6hDMeta?.name ?? "account-products",
    path: productsXqLpoHJ6hDMeta?.path ?? "products",
    meta: productsXqLpoHJ6hDMeta || {},
    alias: productsXqLpoHJ6hDMeta?.alias || [],
    redirect: productsXqLpoHJ6hDMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/products.vue").then(m => m.default || m)
  },
  {
    name: salescGGeL1LZO1Meta?.name ?? "account-sales",
    path: salescGGeL1LZO1Meta?.path ?? "sales",
    meta: salescGGeL1LZO1Meta || {},
    alias: salescGGeL1LZO1Meta?.alias || [],
    redirect: salescGGeL1LZO1Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/sales.vue").then(m => m.default || m)
  },
  {
    name: setupNotificationsifFPyolAS3Meta?.name ?? "account-setupNotifications",
    path: setupNotificationsifFPyolAS3Meta?.path ?? "setupNotifications",
    meta: setupNotificationsifFPyolAS3Meta || {},
    alias: setupNotificationsifFPyolAS3Meta?.alias || [],
    redirect: setupNotificationsifFPyolAS3Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/setupNotifications.vue").then(m => m.default || m)
  },
  {
    name: support_45chatHoo8e9BBnAMeta?.name ?? "account-support-chat",
    path: support_45chatHoo8e9BBnAMeta?.path ?? "support-chat",
    meta: support_45chatHoo8e9BBnAMeta || {},
    alias: support_45chatHoo8e9BBnAMeta?.alias || [],
    redirect: support_45chatHoo8e9BBnAMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/support-chat.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91brand_93Cbph70QR3pMeta?.name ?? "brands-category-brand",
    path: _91brand_93Cbph70QR3pMeta?.path ?? "/brands/:category?/:brand()",
    meta: _91brand_93Cbph70QR3pMeta || {},
    alias: _91brand_93Cbph70QR3pMeta?.alias || [],
    redirect: _91brand_93Cbph70QR3pMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[[category]]/[brand].vue").then(m => m.default || m)
  },
  {
    name: _91category_93A4Pq0F9as0Meta?.name ?? "brands-category",
    path: _91category_93A4Pq0F9as0Meta?.path ?? "/brands/:category()",
    meta: _91category_93A4Pq0F9as0Meta || {},
    alias: _91category_93A4Pq0F9as0Meta?.alias || [],
    redirect: _91category_93A4Pq0F9as0Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[category].vue").then(m => m.default || m)
  },
  {
    name: cartMK3AbecXJZMeta?.name ?? "cart",
    path: cartMK3AbecXJZMeta?.path ?? "/cart",
    meta: cartMK3AbecXJZMeta || {},
    alias: cartMK3AbecXJZMeta?.alias || [],
    redirect: cartMK3AbecXJZMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46catalog_93HiN10xJQY9Meta?.name ?? "catalog-catalog",
    path: _91_46_46_46catalog_93HiN10xJQY9Meta?.path ?? "/catalog/:catalog(.*)*",
    meta: _91_46_46_46catalog_93HiN10xJQY9Meta || {},
    alias: _91_46_46_46catalog_93HiN10xJQY9Meta?.alias || [],
    redirect: _91_46_46_46catalog_93HiN10xJQY9Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/catalog/[...catalog].vue").then(m => m.default || m)
  },
  {
    name: checkoutny9HcvFmgjMeta?.name ?? "checkout",
    path: checkoutny9HcvFmgjMeta?.path ?? "/checkout",
    meta: checkoutny9HcvFmgjMeta || {},
    alias: checkoutny9HcvFmgjMeta?.alias || [],
    redirect: checkoutny9HcvFmgjMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: conciergeGDzwAnPMP6Meta?.name ?? "concierge",
    path: conciergeGDzwAnPMP6Meta?.path ?? "/concierge",
    meta: conciergeGDzwAnPMP6Meta || {},
    alias: conciergeGDzwAnPMP6Meta?.alias || [],
    redirect: conciergeGDzwAnPMP6Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/concierge.vue").then(m => m.default || m)
  },
  {
    name: _91homeBannerId_938o2QAhM6YhMeta?.name ?? "homeBanner-homeBannerId",
    path: _91homeBannerId_938o2QAhM6YhMeta?.path ?? "/homeBanner/:homeBannerId()",
    meta: _91homeBannerId_938o2QAhM6YhMeta || {},
    alias: _91homeBannerId_938o2QAhM6YhMeta?.alias || [],
    redirect: _91homeBannerId_938o2QAhM6YhMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/homeBanner/[homeBannerId].vue").then(m => m.default || m)
  },
  {
    name: indexOHLNSFu8R2Meta?.name ?? "index",
    path: indexOHLNSFu8R2Meta?.path ?? "/",
    meta: indexOHLNSFu8R2Meta || {},
    alias: indexOHLNSFu8R2Meta?.alias || [],
    redirect: indexOHLNSFu8R2Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: aboutWbZjIlgUfyMeta?.name ?? "info-about",
    path: aboutWbZjIlgUfyMeta?.path ?? "/info/about",
    meta: aboutWbZjIlgUfyMeta || {},
    alias: aboutWbZjIlgUfyMeta?.alias || [],
    redirect: aboutWbZjIlgUfyMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/about.vue").then(m => m.default || m)
  },
  {
    name: buyeriGaTIplmp3Meta?.name ?? "info-buyer",
    path: buyeriGaTIplmp3Meta?.path ?? "/info/buyer",
    meta: buyeriGaTIplmp3Meta || {},
    alias: buyeriGaTIplmp3Meta?.alias || [],
    redirect: buyeriGaTIplmp3Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/buyer.vue").then(m => m.default || m)
  },
  {
    name: guaranteesQ6P4ukLMEHMeta?.name ?? "info-guarantees",
    path: guaranteesQ6P4ukLMEHMeta?.path ?? "/info/guarantees",
    meta: guaranteesQ6P4ukLMEHMeta || {},
    alias: guaranteesQ6P4ukLMEHMeta?.alias || [],
    redirect: guaranteesQ6P4ukLMEHMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/guarantees.vue").then(m => m.default || m)
  },
  {
    name: kontakty88gLHAmIVJMeta?.name ?? "info-kontakty",
    path: kontakty88gLHAmIVJMeta?.path ?? "/info/kontakty",
    meta: kontakty88gLHAmIVJMeta || {},
    alias: kontakty88gLHAmIVJMeta?.alias || [],
    redirect: kontakty88gLHAmIVJMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/kontakty.vue").then(m => m.default || m)
  },
  {
    name: ofertaVkcdqhhweKMeta?.name ?? "info-oferta",
    path: ofertaVkcdqhhweKMeta?.path ?? "/info/oferta",
    meta: ofertaVkcdqhhweKMeta || {},
    alias: ofertaVkcdqhhweKMeta?.alias || [],
    redirect: ofertaVkcdqhhweKMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/oferta.vue").then(m => m.default || m)
  },
  {
    name: pressZqu4nQHvRHMeta?.name ?? "info-press",
    path: pressZqu4nQHvRHMeta?.path ?? "/info/press",
    meta: pressZqu4nQHvRHMeta || {},
    alias: pressZqu4nQHvRHMeta?.alias || [],
    redirect: pressZqu4nQHvRHMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/press.vue").then(m => m.default || m)
  },
  {
    name: sellerbOwxuvNpC4Meta?.name ?? "info-seller",
    path: sellerbOwxuvNpC4Meta?.path ?? "/info/seller",
    meta: sellerbOwxuvNpC4Meta || {},
    alias: sellerbOwxuvNpC4Meta?.alias || [],
    redirect: sellerbOwxuvNpC4Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/seller.vue").then(m => m.default || m)
  },
  {
    name: kidsktE2gt4pnZMeta?.name ?? "kids",
    path: kidsktE2gt4pnZMeta?.path ?? "/kids",
    meta: kidsktE2gt4pnZMeta || {},
    alias: kidsktE2gt4pnZMeta?.alias || [],
    redirect: kidsktE2gt4pnZMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/kids.vue").then(m => m.default || m)
  },
  {
    name: lifestyleZdQporhbXKMeta?.name ?? "lifestyle",
    path: lifestyleZdQporhbXKMeta?.path ?? "/lifestyle",
    meta: lifestyleZdQporhbXKMeta || {},
    alias: lifestyleZdQporhbXKMeta?.alias || [],
    redirect: lifestyleZdQporhbXKMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/lifestyle.vue").then(m => m.default || m)
  },
  {
    name: loginUhr5VTSYdrMeta?.name ?? "login",
    path: loginUhr5VTSYdrMeta?.path ?? "/login",
    meta: loginUhr5VTSYdrMeta || {},
    alias: loginUhr5VTSYdrMeta?.alias || [],
    redirect: loginUhr5VTSYdrMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: menf4HlARQYP2Meta?.name ?? "men",
    path: menf4HlARQYP2Meta?.path ?? "/men",
    meta: menf4HlARQYP2Meta || {},
    alias: menf4HlARQYP2Meta?.alias || [],
    redirect: menf4HlARQYP2Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/men.vue").then(m => m.default || m)
  },
  {
    name: _91id_93D5T4XMM9xeMeta?.name ?? "opinions-id",
    path: _91id_93D5T4XMM9xeMeta?.path ?? "/opinions/:id()",
    meta: _91id_93D5T4XMM9xeMeta || {},
    alias: _91id_93D5T4XMM9xeMeta?.alias || [],
    redirect: _91id_93D5T4XMM9xeMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/opinions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMlLUJXreXBMeta?.name ?? "orders-orderId",
    path: indexMlLUJXreXBMeta?.path ?? "/orders/:orderId()",
    meta: indexMlLUJXreXBMeta || {},
    alias: indexMlLUJXreXBMeta?.alias || [],
    redirect: indexMlLUJXreXBMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: thanksr7YFIN2KS2Meta?.name ?? "orders-orderId-thanks",
    path: thanksr7YFIN2KS2Meta?.path ?? "/orders/:orderId()/thanks",
    meta: thanksr7YFIN2KS2Meta || {},
    alias: thanksr7YFIN2KS2Meta?.alias || [],
    redirect: thanksr7YFIN2KS2Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/thanks.vue").then(m => m.default || m)
  },
  {
    name: feedeVUa5PVxwPMeta?.name ?? "osocial-feed",
    path: feedeVUa5PVxwPMeta?.path ?? "/osocial/feed",
    meta: feedeVUa5PVxwPMeta || {},
    alias: feedeVUa5PVxwPMeta?.alias || [],
    redirect: feedeVUa5PVxwPMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/osocial/feed.vue").then(m => m.default || m)
  },
  {
    name: feeduGJCNsaovoMeta?.name ?? "otrends-feed",
    path: feeduGJCNsaovoMeta?.path ?? "/otrends/feed",
    meta: feeduGJCNsaovoMeta || {},
    alias: feeduGJCNsaovoMeta?.alias || [],
    redirect: feeduGJCNsaovoMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/otrends/feed.vue").then(m => m.default || m)
  },
  {
    name: _91product_93rYyo0sS413Meta?.name ?? "products-product",
    path: _91product_93rYyo0sS413Meta?.path ?? "/products/:product()",
    meta: _91product_93rYyo0sS413Meta || {},
    alias: _91product_93rYyo0sS413Meta?.alias || [],
    redirect: _91product_93rYyo0sS413Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91profileId_93PssI6OgWWAMeta?.name ?? undefined,
    path: _91profileId_93PssI6OgWWAMeta?.path ?? "/profile/:profileId()",
    meta: _91profileId_93PssI6OgWWAMeta || {},
    alias: _91profileId_93PssI6OgWWAMeta?.alias || [],
    redirect: _91profileId_93PssI6OgWWAMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId].vue").then(m => m.default || m),
    children: [
  {
    name: indexzGdA3SQnqRMeta?.name ?? "profile-profileId",
    path: indexzGdA3SQnqRMeta?.path ?? "",
    meta: indexzGdA3SQnqRMeta || {},
    alias: indexzGdA3SQnqRMeta?.alias || [],
    redirect: indexzGdA3SQnqRMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/index.vue").then(m => m.default || m)
  },
  {
    name: wishlistEyCNW5XZQQMeta?.name ?? "profile-profileId-wishlist",
    path: wishlistEyCNW5XZQQMeta?.path ?? "wishlist",
    meta: wishlistEyCNW5XZQQMeta || {},
    alias: wishlistEyCNW5XZQQMeta?.alias || [],
    redirect: wishlistEyCNW5XZQQMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexkusVu0PLmyMeta?.name ?? "profile",
    path: indexkusVu0PLmyMeta?.path ?? "/profile",
    meta: indexkusVu0PLmyMeta || {},
    alias: indexkusVu0PLmyMeta?.alias || [],
    redirect: indexkusVu0PLmyMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: konciergesDowZSTDHmMeta?.name ?? "promo-koncierge",
    path: konciergesDowZSTDHmMeta?.path ?? "/promo/koncierge",
    meta: konciergesDowZSTDHmMeta || {},
    alias: konciergesDowZSTDHmMeta?.alias || [],
    redirect: konciergesDowZSTDHmMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/promo/koncierge.vue").then(m => m.default || m)
  },
  {
    name: vtb7QUnP5mmlhMeta?.name ?? "promo-vtb",
    path: vtb7QUnP5mmlhMeta?.path ?? "/promo/vtb",
    meta: vtb7QUnP5mmlhMeta || {},
    alias: vtb7QUnP5mmlhMeta?.alias || [],
    redirect: vtb7QUnP5mmlhMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/promo/vtb.vue").then(m => m.default || m)
  },
  {
    name: publicationxlEpOCSZF6Meta?.name ?? "publication",
    path: publicationxlEpOCSZF6Meta?.path ?? "/publication",
    meta: publicationxlEpOCSZF6Meta || {},
    alias: publicationxlEpOCSZF6Meta?.alias || [],
    redirect: publicationxlEpOCSZF6Meta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/publication.vue").then(m => m.default || m)
  },
  {
    name: uikit7CqsqpK9JvMeta?.name ?? "uikit",
    path: uikit7CqsqpK9JvMeta?.path ?? "/uikit",
    meta: uikit7CqsqpK9JvMeta || {},
    alias: uikit7CqsqpK9JvMeta?.alias || [],
    redirect: uikit7CqsqpK9JvMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/uikit.vue").then(m => m.default || m)
  },
  {
    name: womencV2muBm1TWMeta?.name ?? "women",
    path: womencV2muBm1TWMeta?.path ?? "/women",
    meta: womencV2muBm1TWMeta || {},
    alias: womencV2muBm1TWMeta?.alias || [],
    redirect: womencV2muBm1TWMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/women.vue").then(m => m.default || m)
  },
  {
    name: ya_45authVS2RvS56riMeta?.name ?? "ya-auth",
    path: ya_45authVS2RvS56riMeta?.path ?? "/ya-auth",
    meta: ya_45authVS2RvS56riMeta || {},
    alias: ya_45authVS2RvS56riMeta?.alias || [],
    redirect: ya_45authVS2RvS56riMeta?.redirect,
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/ya-auth.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "community-about",
    path: indexp9V2p5fxycMeta?.path ?? "/community/about",
    meta: { ...(indexp9V2p5fxycMeta || {}), ...{"communityDialog":{"ownerMode":false}} },
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "community-status",
    path: indexp9V2p5fxycMeta?.path ?? "/community/status",
    meta: { ...(indexp9V2p5fxycMeta || {}), ...{"communityDialog":{"ownerMode":true}} },
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "community-landing",
    path: indexp9V2p5fxycMeta?.path ?? "/community/landing",
    meta: { ...(indexp9V2p5fxycMeta || {}), ...{"communityDialog":{}} },
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]