export function isDefined<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export function isDefinedAndIsNotNaN(value: NotDefined<number>): value is number {
  return isDefined(value) && !Number.isNaN(value);
}

export function replaceEmptyArray<T>(value: T[]): T[] | undefined {
  if (Array.isArray(value) && value.length > 0) {
    return value;
  }

  return undefined;
}

export function isArrayEquals(a?: (string | number)[], b?: (string | number)[]) {
  return Array.isArray(a) && Array.isArray(b)
    && a.length === b.length
    && a.every((val, index) => val === b[index]);
}

export function isNumber(str: string | number | undefined | null) {
  return !(Number.isNaN(Number(str)));
}

export function genSPALink(link?: string) {
  const regex = /^https?:\/\/[^/]+/i;

  // TODO: hotfix, исправить на router.resolve
  if (link?.includes('cdn.')) {
    return link;
  }

  return link?.replace(regex, '');
}

export function formatPrice(price: number) {
  return price?.toLocaleString('ru-RU');
}
