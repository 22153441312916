/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfAttributeTree,
  Api2ResponseOfAvailableFilters,
  Api2ResponseOfBoolean,
  Api2ResponseOfBrandDTO,
  Api2ResponseOfCategoryTree,
  Api2ResponseOfListOfBrandDTO,
  Api2ResponseOfListOfCategoryDTO,
  Api2ResponseOfListOflong,
  Api2ResponseOfListOfProductDTO,
  Api2ResponseOfListOfProductImageDTO,
  Api2ResponseOfListOfSizeTypeDTO,
  Api2ResponseOfPageOfBrandDTO,
  Api2ResponseOfPageOflong,
  Api2ResponseOfPageOfProductDTO,
  Api2ResponseOfProductDTO,
  Api2ResponseOfProductDTOLite,
  Api2ResponseOfProductIdDTO,
  Api2ResponseOfSizeTree,
  Api2ResponseOfToggleResult,
  ApiV2ResponseOfCatalogMenu,
  BrandsRequest,
  ProductsRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Catalog<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetBrandsUsingGet
   * @summary getBrands
   * @request GET:/api/v2/catalog/allBrands
   */
  getBrandsUsingGet = (
    data: ProductsRequest,
    query?: {
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfBrandDTO, void>({
      path: `/api/v2/catalog/allBrands`,
      method: "GET",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreeUsingGet
   * @summary getAttributeTree
   * @request GET:/api/v2/catalog/attributeTree
   */
  getAttributeTreeUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreePostUsingPost
   * @summary getAttributeTreePost
   * @request POST:/api/v2/catalog/attributeTree
   */
  getAttributeTreePostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreeColorUsingGet
   * @summary getAttributeTreeColor
   * @request GET:/api/v2/catalog/attributeTree/color
   */
  getAttributeTreeColorUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree/color`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreeColorPostUsingPost
   * @summary getAttributeTreeColorPost
   * @request POST:/api/v2/catalog/attributeTree/color
   */
  getAttributeTreeColorPostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree/color`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreeColorPostV2UsingPost
   * @summary getAttributeTreeColorPostV2
   * @request POST:/api/v2/catalog/attributeTree/color-post
   */
  getAttributeTreeColorPostV2UsingPost = (data: ProductsRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree/color-post`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreeMaterialUsingGet
   * @summary getAttributeTreeMaterial
   * @request GET:/api/v2/catalog/attributeTree/material
   */
  getAttributeTreeMaterialUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      /** @default false */
      flatAttrValues?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree/material`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreeMaterialPostUsingPost
   * @summary getAttributeTreeMaterialPost
   * @request POST:/api/v2/catalog/attributeTree/material
   */
  getAttributeTreeMaterialPostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree/material`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAttributeTreeMaterialPostV2UsingPost
   * @summary getAttributeTreeMaterialPostV2
   * @request POST:/api/v2/catalog/attributeTree/material-post
   */
  getAttributeTreeMaterialPostV2UsingPost = (
    data: ProductsRequest,
    query?: {
      /** @default false */
      flatAttrValues?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAttributeTree, void>({
      path: `/api/v2/catalog/attributeTree/material-post`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAvailableFiltersUsingGet
   * @summary getAvailableFilters
   * @request GET:/api/v2/catalog/availableFilters
   */
  getAvailableFiltersUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingProductModels?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAvailableFilters, void>({
      path: `/api/v2/catalog/availableFilters`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetAvailableFiltersPostUsingPost
   * @summary getAvailableFiltersPost
   * @request POST:/api/v2/catalog/availableFilters
   */
  getAvailableFiltersPostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAvailableFilters, void>({
      path: `/api/v2/catalog/availableFilters`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetBrandsUsingGet1
   * @summary getBrands
   * @request GET:/api/v2/catalog/brands
   */
  getBrandsUsingGet1 = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfBrandDTO, void>({
      path: `/api/v2/catalog/brands`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetBrandsPostUsingPost
   * @summary getBrandsPost
   * @request POST:/api/v2/catalog/brands
   */
  getBrandsPostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfBrandDTO, void>({
      path: `/api/v2/catalog/brands`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetBrandsPostV2UsingPost
   * @summary getBrandsPostV2
   * @request POST:/api/v2/catalog/brands-post
   */
  getBrandsPostV2UsingPost = (
    data: BrandsRequest,
    query?: {
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfBrandDTO, void>({
      path: `/api/v2/catalog/brands-post`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLikedBrandsUsingGet1
   * @summary getLikedBrands
   * @request GET:/api/v2/catalog/brands/liked
   */
  getLikedBrandsUsingGet1 = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfBrandDTO, void>({
      path: `/api/v2/catalog/brands/liked`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLikedBrandsUsingGet
   * @summary getLikedBrands
   * @request GET:/api/v2/catalog/brands/liked/{userId}
   */
  getLikedBrandsUsingGet = (
    userId: number,
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfBrandDTO, void>({
      path: `/api/v2/catalog/brands/liked/${userId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetBrandUsingGet1
   * @summary getBrand
   * @request GET:/api/v2/catalog/brands/name/{urlName}
   */
  getBrandUsingGet1 = (
    urlName: string,
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBrandDTO, void>({
      path: `/api/v2/catalog/brands/name/${urlName}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetTopBrandsManualUsingGet
   * @summary getTopBrandsManual
   * @request GET:/api/v2/catalog/brands/top/manual
   */
  getTopBrandsManualUsingGet = (
    query: {
      /**
       * categoryId
       * @format int64
       */
      categoryId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfBrandDTO, void>({
      path: `/api/v2/catalog/brands/top/manual`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetTopBrandsUsingGet
   * @summary getTopBrands
   * @request GET:/api/v2/catalog/brands/top
   */
  getTopBrandsUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      /**
       * rootCategoryId
       * @format int64
       */
      rootCategoryId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      /**
       * count
       * @format int32
       */
      count?: number;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfBrandDTO, void>({
      path: `/api/v2/catalog/brands/top`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetTopBrandsPostUsingPost
   * @summary getTopBrandsPost
   * @request POST:/api/v2/catalog/brands/top
   */
  getTopBrandsPostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      /**
       * rootCategoryId
       * @format int64
       */
      rootCategoryId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      /**
       * count
       * @format int32
       */
      count?: number;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfBrandDTO, void>({
      path: `/api/v2/catalog/brands/top`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetOurChoiceAvailableCategoriesUsingGet
   * @summary getOurChoiceAvailableCategories
   * @request GET:/api/v2/catalog/ourChoiceCategories
   */
  getOurChoiceAvailableCategoriesUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOflong, void>({
      path: `/api/v2/catalog/ourChoiceCategories`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetBrandUsingGet
   * @summary getBrand
   * @request GET:/api/v2/catalog/brands/{brandId}
   */
  getBrandUsingGet = (
    brandId: number,
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withDescription?: boolean;
      withProducts?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBrandDTO, void>({
      path: `/api/v2/catalog/brands/${brandId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name DislikeBrandUsingPut
   * @summary dislikeBrand
   * @request PUT:/api/v2/catalog/brands/{brandId}/dislike
   */
  dislikeBrandUsingPut = (brandId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/catalog/brands/${brandId}/dislike`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name LikeBrandUsingPut
   * @summary likeBrand
   * @request PUT:/api/v2/catalog/brands/{brandId}/like
   */
  likeBrandUsingPut = (brandId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/catalog/brands/${brandId}/like`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name ToggleLikeBrandUsingPut
   * @summary toggleLikeBrand
   * @request PUT:/api/v2/catalog/brands/{brandId}/toggle
   */
  toggleLikeBrandUsingPut = (brandId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfToggleResult, void>({
      path: `/api/v2/catalog/brands/${brandId}/toggle`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetDirectChildrenCategoriesUsingGet
   * @summary getDirectChildrenCategories
   * @request GET:/api/v2/catalog/categories/{categoryId}
   */
  getDirectChildrenCategoriesUsingGet = (categoryId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfCategoryDTO, void>({
      path: `/api/v2/catalog/categories/${categoryId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetFullCategoryTreeUsingGet
   * @summary getFullCategoryTree
   * @request GET:/api/v2/catalog/fullCategoryTree
   */
  getFullCategoryTreeUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfCategoryTree, void>({
      path: `/api/v2/catalog/fullCategoryTree`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetCategoryTreeUsingGet
   * @summary getCategoryTree
   * @request GET:/api/v2/catalog/categoryTree
   */
  getCategoryTreeUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfCategoryTree, void>({
      path: `/api/v2/catalog/categoryTree`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetCategoryTreePostUsingPost
   * @summary getCategoryTreePost
   * @request POST:/api/v2/catalog/categoryTree
   */
  getCategoryTreePostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfCategoryTree, void>({
      path: `/api/v2/catalog/categoryTree`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetProductIdsPageUsingGet
   * @summary getProductIdsPage
   * @request GET:/api/v2/catalog/product-ids-page
   */
  getProductIdsPageUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOflong, void>({
      path: `/api/v2/catalog/product-ids-page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetProductIdsPagePostUsingPost
   * @summary getProductIdsPagePost
   * @request POST:/api/v2/catalog/product-ids-page
   */
  getProductIdsPagePostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOflong, void>({
      path: `/api/v2/catalog/product-ids-page`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetCatalogUsingGet
   * @summary getCatalog
   * @request GET:/api/v2/catalog/products
   */
  getCatalogUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingProductModels?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: string | number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/catalog/products`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetCatalogPostUsingPost
   * @summary getCatalogPost
   * @request POST:/api/v2/catalog/products
   */
  getCatalogPostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/catalog/products`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetCatalogPostV2UsingPost
   * @summary getCatalogPostV2
   * @request POST:/api/v2/catalog/products-post
   */
  getCatalogPostV2UsingPost = (data: ProductsRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/catalog/products-post`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLastSeenProductsUsingGet
   * @summary getLastSeenProducts
   * @request GET:/api/v2/catalog/products/lastSeen
   */
  getLastSeenProductsUsingGet = (
    query?: {
      /**
       * count
       * @format int32
       */
      count?: number;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/catalog/products/lastSeen`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLastSeenProductsPageUsingGet
   * @summary getLastSeenProductsPage
   * @request GET:/api/v2/catalog/products/lastSeenPage
   */
  getLastSeenProductsPageUsingGet = (
    query?: {
      /**
       * page
       * @format int32
       */
      page?: number;
      /**
       * pageSize
       * @format int32
       */
      pageSize?: number;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/catalog/products/lastSeenPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLikedProductsUsingGet
   * @summary getLikedProducts
   * @request GET:/api/v2/catalog/products/liked
   */
  getLikedProductsUsingGet = (
    query?: {
      currencyCode?: string;
      /** @default false */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/catalog/products/liked`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLikedProductsPageUsingGet
   * @summary getLikedProductsPage
   * @request GET:/api/v2/catalog/products/liked-page
   */
  getLikedProductsPageUsingGet = (
    query?: {
      /**
       * page
       * @format int32
       */
      page?: number;
      /**
       * pageSize
       * @format int32
       */
      pageSize?: number;
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/catalog/products/liked-page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLikedProductsPageUsingGet1
   * @summary getLikedProductsPage
   * @request GET:/api/v2/catalog/products/liked-page/{userId}
   */
  getLikedProductsPageUsingGet1 = (
    userId: number,
    query?: {
      /**
       * page
       * @format int32
       */
      page?: number;
      /**
       * pageSize
       * @format int32
       */
      pageSize?: number;
      currencyCode?: string;
      /** @default false */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/catalog/products/liked-page/${userId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetLikedProductsUsingGet1
   * @summary getLikedProducts
   * @request GET:/api/v2/catalog/products/liked/{userId}
   */
  getLikedProductsUsingGet1 = (
    userId: number,
    query?: {
      currencyCode?: string;
      /** @default false */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/catalog/products/liked/${userId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetFollowingProductsUsingGet
   * @summary getFollowingProducts
   * @request GET:/api/v2/catalog/products/pricefollowings
   */
  getFollowingProductsUsingGet = (
    query?: {
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/catalog/products/pricefollowings`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetProductUsingGet
   * @summary getProduct
   * @request GET:/api/v2/catalog/products/{productId}
   */
  getProductUsingGet = (
    productId: number,
    query?: {
      withSizeChart?: boolean;
      supportCommunity?: boolean;
      currencyCode?: string;
      saleInfoId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfProductDTO, void>({
      path: `/api/v2/catalog/products/${productId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetProductIdBySaleInfoIdUsingGet
   * @summary getProductBySaleInfoId
   * @request GET:/api/v2/catalog/saleinfo
   */
  getProductIdBySaleInfoIdUsingGet = (
    query: {
      saleInfoId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfProductIdDTO, void>({
      path: `/api/v2/catalog/saleinfo`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetProductLiteUsingGet
   * @summary getProductLite
   * @request GET:/api/v2/catalog/products/{productId}/lite
   */
  getProductLiteUsingGet = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfProductDTOLite, void>({
      path: `/api/v2/catalog/products/${productId}/lite`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetProductForBlogUsingGet
   * @summary getProductForBlog
   * @request GET:/api/v2/catalog/products/{productId}/blog
   */
  getProductForBlogUsingGet = (
    productId: number,
    query?: {
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfProductDTO, void>({
      path: `/api/v2/catalog/products/${productId}/blog`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetProductImagesUsingGet
   * @summary getProductImages
   * @request GET:/api/v2/catalog/products/{productId}/images
   */
  getProductImagesUsingGet = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfProductImageDTO, void>({
      path: `/api/v2/catalog/products/${productId}/images`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name DislikeProductUsingPut
   * @summary dislikeProduct
   * @request PUT:/api/v2/catalog/products/{productId}/dislike
   */
  dislikeProductUsingPut = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/catalog/products/${productId}/dislike`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name FollowPriceUsingPut
   * @summary followPrice
   * @request PUT:/api/v2/catalog/products/{productId}/followPrice
   */
  followPriceUsingPut = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/catalog/products/${productId}/followPrice`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name LikeProductUsingPut
   * @summary likeProduct
   * @request PUT:/api/v2/catalog/products/{productId}/like
   */
  likeProductUsingPut = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/catalog/products/${productId}/like`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name ToggleLikeProductUsingPut
   * @summary toggleLikeProduct
   * @request PUT:/api/v2/catalog/products/{productId}/toggle
   */
  toggleLikeProductUsingPut = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfToggleResult, void>({
      path: `/api/v2/catalog/products/${productId}/toggle`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name ToggleLikeToLikeProductUsingPut
   * @summary toggleLikeToLikeProduct
   * @request PUT:/api/v2/catalog/products/{productId}/toggle/like
   */
  toggleLikeToLikeProductUsingPut = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfToggleResult, void>({
      path: `/api/v2/catalog/products/${productId}/toggle/like`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name ToggleLikeToDislikeProductUsingPut
   * @summary toggleLikeToDislikeProduct
   * @request PUT:/api/v2/catalog/products/{productId}/toggle/dislike
   */
  toggleLikeToDislikeProductUsingPut = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfToggleResult, void>({
      path: `/api/v2/catalog/products/${productId}/toggle/dislike`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name UnfollowPriceUsingPut
   * @summary unfollowPrice
   * @request PUT:/api/v2/catalog/products/{productId}/unfollowPrice
   */
  unfollowPriceUsingPut = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/catalog/products/${productId}/unfollowPrice`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetSizeTreeUsingGet
   * @summary getSizeTree
   * @request GET:/api/v2/catalog/sizeTree
   */
  getSizeTreeUsingGet = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfSizeTree, void>({
      path: `/api/v2/catalog/sizeTree`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetSizeTreePostUsingPost
   * @summary getSizeTreePost
   * @request POST:/api/v2/catalog/sizeTree
   */
  getSizeTreePostUsingPost = (
    query?: {
      attributeTreeKeyword?: string;
      /** @format int32 */
      attributeTreeLevelsLimit?: number;
      categoriesIds?: number[];
      /** @format bigdecimal */
      endPrice?: number;
      exceptCategoriesIds?: number[];
      hasOurChoice?: boolean;
      hasSlides?: boolean;
      interestingAttributeValues?: number[];
      interestingBrands?: number[];
      interestingConditions?: number[];
      interestingSizeType?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizeTypes?:
        | "AGE"
        | "AU"
        | "CENTIMETERS"
        | "COLLAR_CENTIMETERS"
        | "COLLAR_INCHES"
        | "DE"
        | "EU"
        | "FR"
        | "HEIGHT"
        | "INCHES"
        | "INT"
        | "IT"
        | "JEANS"
        | "JPN"
        | "NO_SIZE"
        | "BUST"
        | "RING_EUROPEAN"
        | "RING_RUSSIAN"
        | "RU"
        | "UK"
        | "US";
      interestingSizes?: number[];
      isAtOffice?: boolean;
      isDescriptionExists?: boolean;
      isNewCollection?: boolean;
      isOnSale?: boolean;
      isPro?: boolean;
      /** @format int64 */
      isPublishedAfterTimestamp?: number;
      /** @format int64 */
      isPublishedBeforeTimestamp?: number;
      isStartPriceHigher?: boolean;
      isVintage?: boolean;
      isVip?: boolean;
      order?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageLength?: number;
      productsIds?: number[];
      /** @format int64 */
      retoucherId?: number;
      sellerEmailSubstring?: string;
      /** @format int64 */
      sellerId?: number;
      sellerIds?: number[];
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** @format bigdecimal */
      startPrice?: number;
      state?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      storeCodeContains?: string;
      useUserSex?: boolean;
      userLikedBrands?: boolean;
      vendorCodeContains?: string;
      withAttributeTree?: boolean;
      withCategoryTree?: boolean;
      withRrp?: boolean;
      withSizeTree?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfSizeTree, void>({
      path: `/api/v2/catalog/sizeTree`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags catalog-controller-api-v-2
   * @name GetSizesUsingGet
   * @summary getSizes
   * @request GET:/api/v2/catalog/sizes/{categoryId}
   */
  getSizesUsingGet = (categoryId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfSizeTypeDTO, void>({
      path: `/api/v2/catalog/sizes/${categoryId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @name GetApiV2CatalogMenuContent
   * @summary Get Catalog Menu Content
   * @request GET:/api/v2/catalog/menuContent
   */
  getApiV2CatalogMenuContent = (
    query?: {
      version?: string;
      isExperimentalVersion?: boolean;
      productRequestEnabled?: boolean;
      newResaleSplitEnabled?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2ResponseOfCatalogMenu, any>({
      path: `/api/v2/catalog/menuContent`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
