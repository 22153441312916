/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ApiResponse1,
  ApiResponseBannerDTOList,
  ApiResponseInstagramFeedItemListContent,
  ApiResponseListContent,
  ApiResponsePromoBannerContent,
  ApiResponseSocialContent,
  PrimaryPageType,
  ProductFilterItemsRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Home<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags home page
   * @name GetPrimaryPageData
   * @summary Get primary page data
   * @request GET:/api/v2/home
   */
  getPrimaryPageData = (
    query: {
      category: PrimaryPageType;
      pageNumber: number;
      /** @default false */
      supportBannerPage?: boolean;
      countryId?: number;
      /** @default false */
      supportHorizontalSelections?: boolean;
      /** @default false */
      newHomeDesignEnabled?: boolean;
      /** @default false */
      recentlyViewedDisabled?: boolean;
      /** @default false */
      withStories?: boolean;
      /** @default false */
      withStoreez?: boolean;
      /** @default false */
      withInstagramFeed?: boolean;
      /** @default false */
      withSEO?: boolean;
      /** @default false */
      blockListVersion?: number;
      /** @default false */
      withOSocialPostsCollections?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiResponse1, any>({
      path: `/api/v2/home`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags banner page
   * @name GetBannerContent
   * @summary get banner page data
   * @request GET:/api/v2/home/banner
   */
  getBannerContent = (
    query: {
      id: string;
      /** @default 0 */
      pageNumber?: number;
      countryId?: number;
      /** @default false */
      productFiltrationEnabled?: boolean;
      /** @default false */
      withStories?: boolean;
      /** @default false */
      withSEO?: boolean;
      /** @default false */
      blockListVersion?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiResponse1, any>({
      path: `/api/v2/home/banner`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2HomeBannerFilterableItems
   * @summary get banner filterable items
   * @request POST:/api/v2/home/banner/filterableItems
   */
  postApiV2HomeBannerFilterableItems = (
    query: {
      id: string;
      countryId?: string;
    },
    data: ProductFilterItemsRequest,
    params: RequestParams = {},
  ) =>
    this.request<ApiResponse1, any>({
      path: `/api/v2/home/banner/filterableItems`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2HomeFilterableContentItems
   * @summary get filterableContentItems
   * @request POST:/api/v2/home/filterableContentItems
   */
  postApiV2HomeFilterableContentItems = (
    data: ProductFilterItemsRequest,
    query?: {
      countryId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiResponseListContent, any>({
      path: `/api/v2/home/filterableContentItems`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2HomeFilterableItems
   * @summary get filterable items
   * @request POST:/api/v2/home/filterableItems
   */
  postApiV2HomeFilterableItems = (
    data: ProductFilterItemsRequest,
    query?: {
      countryId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiResponse1, any>({
      path: `/api/v2/home/filterableItems`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetApiV2HomePrimaryBanners
   * @summary Get primary banners list
   * @request GET:/api/v2/home/primaryBanners
   */
  getApiV2HomePrimaryBanners = (
    query: {
      category: PrimaryPageType;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiResponseBannerDTOList, any>({
      path: `/api/v2/home/primaryBanners`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetApiV2HomePromoBanners
   * @summary Get promo banner
   * @request GET:/api/v2/home/promo-banner
   */
  getApiV2HomePromoBanners = (params: RequestParams = {}) =>
    this.request<ApiResponsePromoBannerContent, any>({
      path: `/api/v2/home/promo-banner`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags social page
   * @name GetApiV2HomeSocial
   * @summary get social page data
   * @request GET:/api/v2/home/social
   */
  getApiV2HomeSocial = (
    query?: {
      anchor?: string;
      /** @default 0 */
      count?: number;
      selectedIds?: string[];
      /** @default false */
      disableFeed?: boolean;
      /** @default true */
      showStreams?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiResponseSocialContent, any>({
      path: `/api/v2/home/social`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetApiV2HomePromoBannerByBannerId
   * @summary Get promo banner by banner setting id
   * @request GET:/api/v2/home/banner/promo-banner/{bannerSettingId}
   */
  getApiV2HomePromoBannerByBannerId = (bannerSettingId: string, params: RequestParams = {}) =>
    this.request<ApiResponsePromoBannerContent, any>({
      path: `/api/v2/home/banner/promo-banner/${bannerSettingId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetInstagramFeed
   * @summary Get instagram feed
   * @request GET:/api/v2/home/instagram-feed
   */
  getInstagramFeed = (
    query: {
      maxItemsCount: number;
      minLikesCount?: number;
      minCommentsCount?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiResponseInstagramFeedItemListContent, any>({
      path: `/api/v2/home/instagram-feed`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
