import { COUNTRIES } from '~/data/countries';
import { useApi } from '~/restAPI';
import { Address } from '~/restAPI/Address';
import { Account } from '~/restAPI/Account';
import { CountryDTO } from '~/restAPI/data-contracts';

export const useInternational = () => {
  const config = useRuntimeConfig();
  const isInternational = config.public.MARKET_TARGET === 'INTERNATIONAL';
  const marketUrlRussia = config.public.MARKET_URL_RUSSIA;
  const marketUrlInternational = config.public.MARKET_URL_INTERNATIONAL;
  const defaultCountry = isInternational ? COUNTRIES.INTERNATIONAL : COUNTRIES.RUSSIA;

  const { createApiInstance } = useApi();
  const addressApi = createApiInstance(Address);
  const accountApi = createApiInstance(Account);

  const countries = useState<CountryDTO[]>('countries', () => []);

  const currentCountry = useState<CountryDTO>('currentCountry', () => defaultCountry);
  const isCountryDialogOpen = useState('isCountryDialogOpen', () => false);

  const countryCode = useCookie<string>('countryCode', { maxAge: 31536000 });

  async function getCountries(queryCountryCode: string | undefined) {
    const result = await addressApi.getCountriesGet({ context: 'APPLICATION', unionVersion: true });

    if (!result.data.data) {
      countryCode.value = currentCountry.value.isoCodeAlpha2 as string;

      return;
    }

    if (queryCountryCode) {
      countryCode.value = queryCountryCode as string;
    }

    countries.value = result.data.data;

    if (!countryCode.value) {
      countryCode.value = defaultCountry.isoCodeAlpha2 as string;
    }

    const country = countries.value.find((c) => c.isoCodeAlpha2 === countryCode.value);

    if (country) {
      currentCountry.value = country;
    }
  }

  async function changeCountry(country: CountryDTO) {
    if (!isInternational && country.environment === 'INT') {
      window.location.href = `${marketUrlInternational}?countryCode=${country.isoCodeAlpha2}`;
    } else if (isInternational && country.environment === 'RU') {
      window.location.href = `${marketUrlRussia}?countryCode=${country.isoCodeAlpha2}`;
    } else {
      countryCode.value = country.isoCodeAlpha2 as string;
      currentCountry.value = country;
      await updateCountryInAccount();
      reloadNuxtApp({
        force: true,
      });
    }
  }

  async function updateCountryInAccount() {
    await accountApi.accountAddressCountrySetUsingPost({
      countryId: currentCountry.value.id as number,
    });
  }

  return {
    countries,
    countryCode,
    currentCountry,
    isInternational,
    isCountryDialogOpen,
    getCountries,
    changeCountry,
  };
};
