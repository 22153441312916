/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Api2ResponseOfDeeplinkResolveResultOfobject, PrimaryPageCondition } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Deeplink<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags deeplink-controller-api-v-2
   * @name ResolveLinkUsingPost
   * @summary resolveLink
   * @request POST:/api/v2/deeplink
   */
  resolveLinkUsingPost = (
    query?: {
      /** link */
      link?: string;
      /** withSEO */
      withSEO?: boolean;
      /** primaryPageCondition */
      primaryPageCondition?: PrimaryPageCondition;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfDeeplinkResolveResultOfobject, void>({
      path: `/api/v2/deeplink`,
      method: "POST",
      query: query,
      ...params,
    });
}
