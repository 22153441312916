import { TGenderPagesValues } from '~/types/main';
import { TParentCategoryBackend } from '~/types/components/search';

export const MAPPING_PARENT_CATEGORY_FOR_BACKEND_NAMES: Record<NonNullable<TGenderPagesValues>, TParentCategoryBackend> = {
  women: 'woman',
  men: 'man',
  kids: 'kids',
  lifestyle: 'lifestyle',
};

export const parentCategoryWomenId = 2;
export const parentCategoryMenId = 105;
export const parentCategoryKidsId = 188;
export const parentCategoryLifestyleId = 366;
