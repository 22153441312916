export default defineNuxtRouteMiddleware(async (to) => {
  if (!to.name) return;

  const {
    isAuth, firstLoaded,
  } = useAccountStore();
  const { getGlobalSettings } = useGlobalSettings(to);

  if (isAuth.value || (firstLoaded.value && !isAuth.value)) return;

  try {
    await getGlobalSettings();
  } catch (e) {
    console.error(e);
  }
});
